
<template>
   <form class="">
      <div class="row g-3 g-md-4">
        <div class="col-12">
          <label>Business name</label>
          <input type="text" v-model="data.business_name" class="form-control"
          name="business_name" placeholder="Business name" :class="{ 'is-invalid': v$.data.business_name.$error }">
          
          <div v-for="(item, index) in v$.data.business_name.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>      
        </div>
        <div class="col-md-6">
          <label>First name</label>
          <input type="text" v-model="data.first_name" name="first_name" class="form-control" placeholder="First name"
          :class="{ 'is-invalid': v$.data.first_name.$error }">
          
          <div v-if="v$.data.first_name.$error"
            class="invalid-feedback">
            <span v-if="v$.data.first_name.required.$message">
              {{ v$.data.first_name.required.$message }}</span>
          </div>
        </div>
        <div class="col-md-6">
          <label>Last name</label>
          <input type="text" v-model="data.last_name" name="last_name" class="form-control" placeholder="Last name"
          :class="{ 'is-invalid': v$.data.last_name.$error  }">
          
          <div v-if="v$.data.last_name.$error"
            class="invalid-feedback">
            <span v-if="v$.data.last_name.required.$message">
              {{ v$.data.last_name.required.$message }}</span>
          </div>
        </div>
        <div class="col-md-6">
          <label>Email address</label>
          <input type="email" v-model="data.email" class="form-control"
          name="email" placeholder="Enter email" :class="{ 'is-invalid': v$.data.email.$error }">
          
          <div v-for="(item, index) in v$.data.email.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>      
        </div>
        <div class="col-md-6">
          <label>Phone number</label>
          <input type="text" v-model="data.mobile"  class="form-control"
          name="phone" v-maska data-maska="(###) ###-####" placeholder="Enter phone number"
          :class="{ 'is-invalid': v$.data.mobile.$error  }">
          <div v-if="v$.data.mobile.$error"
            class="invalid-feedback">
            <span v-if="v$.data.mobile.required.$message">
              {{ v$.data.mobile.required.$message }}</span>
          </div>
        </div>
        <div class="col-md-12">
          <label>Country</label>
          <multiselect 
            mode="single"
            searchable
            valueProp="id"
            :class="{ 'is-invalid': v$.data.country_id.$error  }"
            trackBy="name" label="name"
            :options="countries" 
            v-model="data.country_id" placeholder="--select--"></multiselect>

          <div v-if="v$.data.country_id.$error"
            class="invalid-feedback">
            <span v-if="v$.data.country_id.required.$message">
              {{ v$.data.country_id.required.$message }}</span>
          </div>
        </div>
        <div class="col-md-12">
          <label>Partner type</label>
          <select v-model="data.partner_type" name="partner_type"
          class="form-select" :class="{ 'is-invalid': v$.data.partner_type.$error  }">
            <option value="" disabled>--Partner Type--</option>
            <option
                v-for="(pType, tIndex) in partnerTypes"
                :value="pType.title"
                :key="tIndex" > {{pType.title}} </option>
          </select>
          <div v-for="(item, index) in v$.data.partner_type.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
        </div>
        <div class="col-md-12">
          <label>Password</label>
          <input type="password" v-model="data.password" class="form-control"
          name="password" placeholder="Enter your password" :class="{ 'is-invalid': v$.data.password.$error }">
          <div v-for="(item, index) in v$.data.password.$errors" :key="index"
            class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>
        </div>
        <div class="col-12">
          <p class="font-14 my-3">By clicking Sign up, you agree to our <a class="text-primary" target="_blank" :href="absoluteUrl('/terms-conditions')">Terms and Conditions.</a></p>
          <div class="d-grid">
            <button @click.prevent="submitData()" class="btn btn-primary"  type="button">
              {{btnName}}
            </button>
          </div>
        </div>
      </div>
    </form>
</template>
<script>

import Multiselect from '@vueform/multiselect'
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    Multiselect,
  },
  name: 'user-signup-form',
  setup() {
    return { v$: useVuelidate() };
  },
  props:{
    btnName:{
      type: String,
      default:"Sign up"
    }
  },
  data() {
    return {
      data: {
        business_name:"",
        partner_type: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        country_id: "",
        password: "",
        role: "partner"
      },
      passwordType: "password",
    }
  },
  validations: {
    data:{
      business_name: {
        required: helpers.withMessage("Business name is required", required),
      },
      partner_type: {
        required: helpers.withMessage("Partner type is required", required),
      },
      first_name: {
        required: helpers.withMessage("First name is required", required),
      },
      last_name: {
        required: helpers.withMessage("Last name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      mobile: {
        required: helpers.withMessage("Phone number is required", required),
      },
      country_id: {
        required: helpers.withMessage("Country is required", required),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
        minLength: helpers.withMessage("minimum of 6 characters", minLength(6))
      },
    }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    partnerTypes(){
      return this.$store.state.partnerTypes
    }
  },
  methods: {
    passwordVisibility(){
      this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
    },
    submitData(){
      this.v$.$validate().then(result =>{
        if (!result) { this.alertError(this.v$.$errors[0].$message); return };
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(this.absoluteUrl("/api/p-signup"), formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.takeAction(response.data.data)
          }
        })
      });
    },
    takeAction(data){
      this.resetForm()
      this.$emit("takeAction", {...data})
    },
    resetForm(){
      this.data = {
        business_name:"",
        partner_type:"",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        country_id: "",
        password: "",
        role: "partner"
      }
      this.v$.$reset()
    },
  }
}
</script>

<style lang="scss">
select:invalid{
  color: #869ab8
}
</style>


<template>
<div class="user-register">
  <div class="container">
    <div class="row gx-lg-0 justify-content-center">
      <div class="col-md-8 col-lg-6">   
        <div class="card mb-0">
          <div class="card-body p-4 p-md-5 p-lg-6">
            <div class="mb-4 text-center">
              <h1 class="h3">Sign up</h1>
            </div>
            <user-signup-form @takeAction="takeAction($event)" />
            <p class="mb-0 mt-4 text-center" style="color:#999999">
              Already have an account?  <router-link to="/login" class="text-success fw-bold" >Log in</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import UserSignupForm from '@/components/forms/UserSignupForm.vue'

export default {
  components:{
    UserSignupForm,
  },
  methods: {
    takeAction(data){
      if(data.url){window.location.replace(data.url); return}
      this.$store.commit('SET_AUTH_TOKEN', data);
      location.replace('/properties?create=1')
    },
  },
}
</script>
